import { UrlLink } from '@/configs/urlLink';
import { KnowledgeHomepageQuery } from '@/generated/graphql';
import _format from '@/utils/format';
import React from 'react';
import Link from 'next/link';
import useGetDetailPlaylistById from '@/hooks/Knowledge/useGetDetailPlaylistById';
import bgAuthor from '@/assets/images/bg-author.png';
import LazyImage from '../shared/LazyImage';
import { ILecturer } from '@/interfaces/Author';

export interface CardPlaylistProps {
  data?: ILecturer;
  isLoading: boolean;
  onClick?: () => void;
}
const CardPlaylistSkeleton = (
  <div className="bg-gray-variants-2 flex h-full w-56 animate-pulse flex-col gap-4 rounded-2xl p-4">
    <div className="bg-gray-variants-8 h-48" />
    <div className="bg-gray-variants-8 h-6 w-1/2 rounded-lg" />
    <div className="bg-gray-variants-8 h-6 w-full rounded-lg" />
  </div>
);
const AuthorCard = ({ data, isLoading }: CardPlaylistProps) => {
  return (
    <>
      {isLoading ? (
        CardPlaylistSkeleton
      ) : (
        <Link
          className={`flex h-[420px] cursor-pointer flex-col rounded-[4px] duration-150 hover:scale-105  border-[1px] border-[#DCDEE0]`}
          href={`${UrlLink.knowledge.author}/${data?.slug}`}
        >
          <div className="flex flex-col items-center justify-center">
            <div className="bg-primary relative h-[172px] w-full md:h-[172px] lg:h-[172px]">
              <LazyImage
                src={data?.image || bgAuthor}
                alt="Thumbnail course"
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 768px) 50vw, 25vw"
                className="object-fill"
                style={{ borderRadius: '4px' }}
              />
            </div>
          </div>

          <div className="flex flex-col px-3">
            <div className="flex flex-col border-neutral-grayLight line-clamp-2 items-center py-2">
              <h3 className="py-1 text-PC-Title-Large text-[#020509] lg:text-PC-Title-Regular hover:text-primary-eastern line-clamp-2 font-sans pb-[8px]">
                {data?.name}
              </h3>
              <h3 className="text-PC-Subtitle-Small text-neutral-gray line-clamp-3 font-sans pb-[16px]">
                Chuyên gia {data?.major}
              </h3>

              <span className="text-neutral-dark text-PC-Body-Medium text-justify line-clamp-6">
                {data?.description}
              </span>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default AuthorCard;
