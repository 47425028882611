import { LeftArrowIcon1, RightArrowIcon1 } from '@/assets/icons';
import { UrlLink } from '@/configs/urlLink';
import Link from 'next/link';
import { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import DetailCourseModal from '../Course/DetailCourseModal';
import AuthorCard from '../Course/AuthorCard';
import { useGetAllLecturerQuery } from '@/generated/graphql';

const AuthorHomepageCard = () => {
  const [showModal, setShowModal] = useState(false);
  const customSlider = useRef<Slider>(null);
  const goToPrev = () => customSlider?.current?.slickPrev();
  const goToNext = () => customSlider?.current?.slickNext();

  const settingsMobile: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    variableWidth: true,
  };

  const { data: listAllLecturers } = useGetAllLecturerQuery();

  const listAllLecturersLength = listAllLecturers?.lecturer?.length;

  return (
    <div className="container">
      <Link
        className="border-neutral-grayLight hover:text-primary-eastern flex justify-center items-center gap-5 border-b-[0.5px] pb-2 lg:border-b-[0px]"
        href={UrlLink.knowledge.author}
      >
        <h2 className="font-header text-2xl font-semibold">Được dẫn dắt bởi</h2>
      </Link>
      <div className="slider-container relative">
        {listAllLecturersLength ? (
          <div className="py-4">
            <Slider
              {...{
                ...settingsMobile,
                infinite: listAllLecturersLength < 4 ? false : true,
              }}
              ref={customSlider}
            >
              {listAllLecturers?.lecturer?.map((item, index) => {
                return (
                  <div key={index} className="!w-[284px] p-2 pb-4">
                    <div
                      className={`bg-gray-variants-1 md:bg-gray-variants-2 
                                cursor-pointer rounded-xl `}
                    >
                      <AuthorCard key={index} data={item} isLoading={false} />
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div className="hidden md:block">
              <div
                className={`left-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                  Number(listAllLecturersLength) > 5 ? 'block' : 'hidden'
                }`}
              >
                <button onClick={goToPrev} className="cursor-pointer">
                  <LeftArrowIcon1 />
                </button>
              </div>
              <div
                className={`right-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                  Number(listAllLecturersLength) > 5 ? 'block' : 'hidden'
                }`}
              >
                <button onClick={goToNext}>
                  <RightArrowIcon1 className="cursor-point" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {showModal && <DetailCourseModal setShowModal={setShowModal} />}
    </div>
  );
};

export default AuthorHomepageCard;
